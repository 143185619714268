import { Box } from "@components/customized"
import IScreen from "./interfaces"
import Text from "@components/controllers/text/text.component"
import { ErrorBoundary } from "react-error-boundary"
import Breadcrumbs from "./bread-crumbs"
import ScrollToTop from "@router/scroll-to-top"

const Screen: React.FC<IScreen> = (props) => {
  const { children, disableBreadcrumbs, subTitle, title = "", ...rest } = props

  const container = document.querySelector("#main-container")
  ScrollToTop(container)

  return (
    <Box
      {...rest}
      className="relative flex 
      h-full 
      animate-fade-out
      flex-col
      gap-y-2
      min-h-screen
      bg-[var(--color-light-gray)] 
      p-3 

      "
    >
      <Box className="flex flex-col">
        {!disableBreadcrumbs && <Breadcrumbs />}
        {title && (
          <Text
            type="header6"
            weight="bold"
            className="leading-tight text-[var(--color-primary)]"
          >
            {title}
          </Text>
        )}
        {subTitle && (
          <Text type="text2" className="leading-none">
            {subTitle}
          </Text>
        )}
      </Box>
      <ErrorBoundary FallbackComponent={() => <div>Error</div>}>
        {children}
      </ErrorBoundary>
    </Box>
  )
}

export default Screen
