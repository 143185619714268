import networkService from "services/network/network.service"

export type Creator = {
  id: number
  fullName: string
  avatar: string
  createdAt: string
}
export type CreatorName = {
  id: number
  fullName: string
}
export type Vertical = { id: number; vertical: string }
export type Category = { id: number; category: string }
export type Activity = {
  id: number
  vertical: Vertical & {
    categoryId?: number
  }
  category: Category
}
export type Categories = Array<
  Category & {
    verticals: Array<Vertical>
  }
>

class CommonService {
  async getCreators(): Promise<Creator[]> {
    return await networkService.axios.get("/creators").then((res) => res.data)
  }
  async getCreatorsNames(): Promise<Creator[]> {
    return await networkService.axios
      .get("/creators/names")
      .then((res) => res.data)
  }
  async getCategories(getFullList?: boolean): Promise<Categories> {
    const endpoint = getFullList ? "/categories/full-list" : "/categories"
    return networkService.axios
      .get(endpoint)
      .then((res) => res.data)
      .catch((error) => {
        console.log(error)
        return []
      })
  }
}

const commonService = new CommonService()
export default commonService
