import Env from "@utils/env"
import networkService from "services/network/network.service"

async function generateUsingText(
  text: string,
  userId: string,
  isLead: boolean
) {
  const url = `${Env.get("REACT_APP_BASE_URL")}/ai/video/generate-from-text`

  return await networkService.axios
    .post(url, { text, userId, isLead })
    .catch((error) => {
      const err = error.response.data.message
      console.log(error)
      if (error.response.data.errorCode === "COINS_REACH_LIMIT") {
        throw new Error("COINS_REACH_LIMIT")
      }

      throw new Error(err)
    })
}

export default generateUsingText
