import { useEffect, useState } from "react"
import ordersService from "services/api/REST/orders"

interface ISwapInfo {
  orderId?: string
  userId?: string
}

function useSwapManager() {
  const [swapInfo, setSwapInfo] = useState<ISwapInfo>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)

  const handleSwapInfo = (params: ISwapInfo) => {
    setSwapInfo((prev) => ({ ...prev, ...params }))
  }

  const handleSubmitSwap = async () => {
    if (!swapInfo?.orderId || !swapInfo?.userId?.length) return
    console.log(swapInfo)

    const { orderId, userId } = swapInfo

    try {
      setIsLoading(true)
      await ordersService.swapUserOrder({
        orderId,
        userId,
      })

      handleClearSwapInfo()
      setIsLoading(false)
      setShowSuccessMessage(true)
    } catch (error) {
      console.warn(error)
      setIsLoading(false)
    }
  }

  const handleClearSwapInfo = () => {
    setSwapInfo(undefined)
  }

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false)
      }, 3000) // 3 seconds after show success message, hide it.
    }
  }, [showSuccessMessage])
  return {
    handleSwapInfo,
    swapInfo,
    handleSubmitSwap,
    handleClearSwapInfo,
    isLoading,
    showSuccessMessage,
  }
}

export default useSwapManager
