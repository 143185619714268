import userStore from "@stores/user/user.store"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "@router/router"
import { useMemo } from "react"
import { Text } from "@components/controllers"
import { cn } from "@utils/styles/cn"
import { Icons, Popover, Divider, Box, Avatar } from "@components/customized"
import { UserRoles } from "services/api/REST/users/users-api.interfaces"
import { useLogout } from "@utils/hooks"
import { MenuProps } from "antd"

const UserDropdown = () => {
  const logout = useLogout()
  const user = userStore.user
  const userNameInitialChar = user?.fullName?.charAt(0)
  const navigate = useNavigate()
  const isAdmin = userStore.user?.type === UserRoles.Admin

  const links = useMemo(() => {
    const links = [
      {
        title: "My account",
        onClick: () => navigate(routes.account.root),
        disabled: false,
        icon: Icons.User,
      },
    ]

    if (isAdmin) {
      links.push({
        title: "Assets explorer",
        onClick: () => navigate(routes.admin.explorer),
        icon: Icons.FormatPainterOutlined,
        disabled: false,
      })

      links.push({
        title: "Draft orders",
        onClick: () => navigate(routes.admin.draftOrders),
        icon: Icons.DraftOrder,
        disabled: false,
      })

      links.push({
        title: "Swap users order",
        onClick: () => navigate(routes.admin.swapUsesOrder),
        icon: Icons.SwapUsersOrder,
        disabled: false,
      })
    }
    links.push({
      title: "Log out",
      onClick: logout,
      disabled: false,
      icon: Icons.Logout,
    })
    return links
  }, [isAdmin, logout, navigate])

  const uploadSubLinks = [
    {
      path: routes.admin.uploadAsset.hook,
      label: "Upload Hook",
    },
    {
      path: routes.admin.uploadAsset.story,
      label: "Upload Story",
    },
    {
      path: routes.admin.uploadAsset.cta,
      label: "Upload CTA",
    },
  ]

  const uploads = [
    {
      key: "1",
      label: <Text type="text2">Upload</Text>,
      children: uploadSubLinks.map((item, index) => ({
        key: `1-${index}`,
        label: (
          <Link
            to={item.path}
            className={cn(
              `flex items-center gap-x-2 rounded-[var(--b-radius)] p-2 
              hover:bg-[var(--color-light-gray)]`
            )}
          >
            <Text type="text2">{item.label}</Text>
          </Link>
        ),
      })),
    },
  ] as MenuProps["items"]

  return (
    <Popover
      placement="leftBottom"
      content={() => (
        <div className="flex flex-col">
          <Divider className="m-1" />
          <div className="flex flex-col gap-y-1">
            {links.map((link) => (
              <div
                onClick={link.onClick}
                key={link.title}
                className={cn(
                  `flex cursor-pointer items-center gap-x-2 
                rounded-[var(--b-radius)]
                p-2 hover:bg-[var(--color-light-gray)]`,
                  link.disabled && "pointer-events-none opacity-40"
                )}
              >
                <link.icon />
                <Text type="text2">{link.title}</Text>
              </div>
            ))}
          </div>
          {!isAdmin && (
            <div className="mt-2">
              <Divider className="m-1" />
              <Text type="text2" className="mb-1">
                Uploads
              </Text>
              {uploads &&
                (uploads[0] as any)?.children?.map((upload: any) => (
                  <div key={upload.key}>{upload.label}</div>
                ))}
            </div>
          )}
        </div>
      )}
      showArrow={false}
      title={
        <Box className="flex flex-col">
          <Text type="text2">{user?.fullName}</Text>
          <Text type="text3" className="text-[var(--color-dark-gray)]">
            {user?.email}
          </Text>
        </Box>
      }
    >
      <Avatar
        className="cursor-pointer rounded-[var(--b-radius)] 
      bg-[var(--color-primary)]  text-[18px]"
      >
        {userNameInitialChar || <Icons.User />}
      </Avatar>
    </Popover>
  )
}

export default UserDropdown
