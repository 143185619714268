import React from "react"
import { Modal, Button } from "antd"

interface IUserGuideModal {
  showGuideModal: boolean
  closeModal: () => void
}
const UserGuideModal = ({ showGuideModal, closeModal }: IUserGuideModal) => {
  const guideContent = `
  <body style="font-family: Arial, sans-serif; color: #333; line-height: 1.6; padding: 20px;">
    <h3 style="color: #0056b3;">Getting Started with Letstok.ai: Creating Your Video Ad Prompt</h3>
    <ol style="padding-left: 20px;">
      <li><strong>Use a Clear Prompt:</strong> Start your prompt with <strong>“Please create a video ad about…”</strong> and add the details of your business, product, or service. Be specific to help the AI capture the essence of your ad.</li>
    </ol>
    <ul style="padding-left: 20px;">
      <li><strong>Example:</strong> “Please create a video ad about my coffee shop, featuring our new seasonal flavors and cozy atmosphere.”</li>
    </ul>
    <ol start="2" style="padding-left: 20px;">
      <li><strong>Select a Template:</strong> To make it even easier, you’ll find <strong>four ready-made templates</strong> above the text box. Click on any template to instantly load a structure you can customize:</li>
    </ol>
    <ul style="padding-left: 20px;">
      <li><strong>💼 Business Showcase</strong> – Highlight your business's unique qualities and values.</li>
      <li><strong>🛒 New Product or Service Launch</strong> – Perfect for introducing new offerings to your audience.</li>
      <li><strong>📣 Promotion of a Sale or App</strong> – Promote a sale, special discount, or new app with engaging visuals.</li>
      <li><strong>📋 Customer Testimonial</strong> – Share authentic customer experiences and feedback.</li>
    </ul>
    <ol start="3" style="padding-left: 20px;">
      <li><strong>Customize Your Template:</strong> Once you’ve chosen a template, personalize it to fit your specific needs. Adjust product names, special features, or sale details as relevant to your business.</li>
      <li><strong>Review and Submit:</strong> Double-check your text prompt for clarity and make sure it’s detailed enough to convey the message you want in the video ad.</li>
      <li><strong>Receive Your Video:</strong> After submitting, Letstok.ai will create your video ad and send it to your email within 2 minutes. You can create up to <strong>3 free video ads per email</strong>.</li>
    </ol>
  </body>`

  return (
    <Modal
      title="User Guide"
      open={showGuideModal}
      onCancel={() => {
        closeModal()
      }}
      footer={[
        <Button key="close" onClick={closeModal}>
          Close
        </Button>,
      ]}
    >
      <iframe
        srcDoc={guideContent}
        style={{
          width: "100%",
          height: "70vh",
          border: "none",
          padding: "0px",
        }}
        title="User Guide"
      />
    </Modal>
  )
}

export default UserGuideModal
