import { Button, Form, Modal } from "@components/controllers"
import { Box } from "@components/customized"
import { Input } from "antd"
import React, { useMemo, useState } from "react"
import { LeadUserDTO } from "services/api/REST/assets/types"
import addUnsignedUser from "services/api/REST/users/add-unsigned-user.service"
import "@screens/text-generator/popup/popup.css"
import disposableDomains from "./email-helpers/disposable-domains"
import freeDomains from "./email-helpers/free-domains"

interface PopupProps {
  onClose: () => void
  submitText: () => void
}

const Popup: React.FC<PopupProps> = ({ onClose, submitText }) => {
  const [fullName, setFullName] = useState<string>("")
  const [linkedin, setLinkedin] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  // const [phoneNumber, setPhoneNumber] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async () => {
    setError(null)
    setLoading(true)
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      console.log(email)
      setError("Please enter a valid email")
      setLoading(false)
      return
    }
    const domain = email.split("@")[1].toLowerCase()
    // if (freeDomains.has(domain)) {
    //   setError("Please enter a work email")
    //   setLoading(false)
    //   return
    // }
    if (disposableDomains.has(domain)) {
      setError("Please enter a valid non-disposable email.")
      setLoading(false)
      return
    }

    try {
      const userEntity = await addUnsignedUser({
        fullName,
        email,
        url: linkedin,
      })

      if (userEntity.email && userEntity.userId) {
        localStorage.setItem("userId", userEntity.userId)
        localStorage.setItem("email", userEntity.email)

        const script = document.createElement("script")
        script.src = require("scripts/lead")
        onClose()
        submitText()
      } else throw new Error("Failed to create lead user.")
    } catch (error: any) {
      setError(error.message)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const FormItem = useMemo(() => Form.Item<LeadUserDTO>, [])

  return (
    <Modal
      title="Enter Your Details"
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <FormItem
          label="Full Name"
          name="fullName"
          rules={[{ required: true, message: "Full name is required" }]}
        >
          <Input
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter your full name"
            size="large"
          />
        </FormItem>

        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            size="large"
          />
        </FormItem>

        <FormItem label="LinkedIn Profile" name="url">
          <Input
            type="url"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
            placeholder="Enter your LinkedIn profile URL"
            size="large"
          />
        </FormItem>

        {/* <FormItem label="Phone Number" name="phone">
          <Input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            size="large"
          />
        </FormItem> */}

        {error && <p style={{ color: "red", marginBottom: "15px" }}>{error}</p>}

        <FormItem>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </FormItem>
      </Form>
    </Modal>
  )
}

export default Popup
