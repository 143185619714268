import networkService from "services/network/network.service"
import { AIVideo } from "./videos-api.interfaces"
import Env from "@utils/env"

async function getAIVideoById(id: number): Promise<AIVideo> {
  const url =
    `${Env.get("REACT_APP_BASE_URL")}/ai/video/ai-generator-video/` + id

  const video: AIVideo = await networkService
    .fetch(url, {
      method: "GET",
    })
    .catch((error) => {
      console.log(error)
      throw new Error("The request to the server failed.")
    })

  return video
}

export default getAIVideoById
