import { Box, Card, Screen } from "@components/customized"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { Icons } from "@components/customized"
import TextareaAutosize from "react-textarea-autosize"
import ExampleCard from "./example-card"
import { useNavigate } from "react-router-dom"
import { routes } from "@router/router"
import generateUsingText from "services/api/REST/videos/text-generator.service"
import generateUsingUrl from "services/api/REST/videos/url-generator.service"
import userStore from "@stores/user/user.store"
import Popup from "./popup"
import { Modal } from "components/controllers"
import { Button, Tooltip } from "antd"
import UserGuideModal from "./user-guide"

type InputChangeEvent = ChangeEvent<HTMLTextAreaElement>

const TextGeneratorScreen = () => {
  const isUserLoggedIn = userStore.loggedIn
  const [showGuideModal, setShowGuideModal] = useState(false)

  useEffect(() => {
    if (!isUserLoggedIn) {
      document.title = "Letstok AI video generator"
      const link = document.querySelector("link[rel='icon']") as HTMLLinkElement
      if (link) {
        link.href = "ai_logo_215.png"
      }

      const script = document.createElement("script")
      script.src = require("scripts/pageView")
    }
  }, [isUserLoggedIn])

  const navigate = useNavigate()
  const [inputText, setInputText] = useState<string>(
    "Please create a video ad about "
  )
  const [isMultiLine, setIsMultiLine] = useState<boolean>(false)
  const [isUrlMode, setIsUrlMode] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showProcessingModal, setShowProcessingModal] = useState<boolean>(false)

  const cards = [
    {
      icon: "💼",
      title: "Business Showcase",
      text: "Please create a video ad about PhoneHub, an e-commerce store for smartphones, accessories, and tech gadgets. Highlight brands like Apple, Samsung, and Google, with features like competitive pricing, free shipping, and warranties.",
    },
    {
      icon: "🛒",
      title: "New Product or Service Launch",
      text: "Please create a video ad about Letstok AI Video Generator, highlighting text-to-video creation in under 2 minutes, customizable templates, and a free trial. Target digital marketers and social media managers.",
    },
    {
      icon: "📣",
      title: "Promotion of a Sale or App",
      text: "Please create a video ad about the PowerQuest Gaming App with 50% off in-game purchases this weekend. Highlight multiplayer modes, daily challenges, and immersive graphics to attract casual gamers.",
    },
    {
      icon: "📋",
      title: "Customer Testimonial",
      text: "Please create a video ad featuring a testimonial for SafeDrive Insurance, covering affordable coverage and flexible plans that save customers $400 annually. Encourage viewers to get a personalized quote.",
    },
  ]

  const handleInputChange = (event: InputChangeEvent) => {
    setInputText(event.target.value)
  }

  const handleHeightChange = (height: number) => {
    setIsMultiLine(height > 40)
  }

  const handleCardClick = (text: string) => {
    setIsUrlMode(false)
    setInputText(text)
    setErrorMessage(null)
  }

  const handleSubmit = useCallback(async () => {
    const userId = isUserLoggedIn
      ? userStore.user?.userId
      : localStorage.getItem("userId")

    if (!inputText.trim()) return

    if (userId) {
      await sendTextToGenerateVideo(userId, !isUserLoggedIn)
    } else {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText, isUserLoggedIn])

  const sendTextToGenerateVideo = async (userId: string, isLead: boolean) => {
    setIsLoading(true)
    setErrorMessage(null)
    try {
      if (isUrlMode) {
        if (!isValidUrl(inputText.trim())) {
          setErrorMessage("Please enter a valid URL.")
          return
        }
        await generateUsingUrl(inputText.trim(), userId, isLead)
      } else {
        await generateUsingText(inputText, userId, isLead)
      }
      if (isUserLoggedIn) {
        navigate(routes.myVideos)
      } else {
        setShowProcessingModal(true)
        setInputText("")
      }
    } catch (error: any) {
      console.error(error)
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url)
      return true
    } catch {
      return false
    }
  }

  const styleObject = isUserLoggedIn
    ? {}
    : {
        backgroundImage: `url("https://cdn.prod.website-files.com/600a7682415ab7d3aafe3a5c/60a77131308f92eca2edfa50_Ellipse%20105-min.png")`,
        fontFamily: `'Plus Jakarta Sans', sans-serif`,
      }

  return (
    <Screen
      title={isUserLoggedIn ? "Text Generator" : ""}
      subTitle={isUserLoggedIn ? "Enter text to generate a video" : ""}
      disableBreadcrumbs={!isUserLoggedIn}
      style={styleObject}
    >
      {!isUserLoggedIn && (
        <Box className="flex flex-col">
          {" "}
          <a href="https://www.letstok.com">
            <Icons.AILogo style={{ width: "150px" }} />
          </a>
        </Box>
      )}
      {!isUserLoggedIn && (
        <Box className="mb-6 mt-1 text-center">
          <h1 className="mb-1 text-2xl font-extrabold text-gray-900">
            For demo purposes only; no commercial license included.
          </h1>
          <div className="hidden text-lg text-gray-700 md:block">
            <p>
              Type a text prompt or webpage link, and Letstok will send a custom
              video ad to your email.
            </p>
            <p>Free, fast, and no sign-up required!</p>
            <p>Up to 3 free video ads per user. Start now!</p>
          </div>
        </Box>
      )}
      <Card
        className={`${isUserLoggedIn ? "mt-10" : ""} flex w-full max-w-[850px] flex-col items-center justify-center self-center`}
      >
        <Icons.AILogo style={{ width: "100px" }} />
        <Box className="mb-16 mt-4 grid gap-4 p-8 md:grid-cols-4">
          {cards.map((card, index) => (
            <ExampleCard
              key={index}
              icon={card.icon}
              title={card.title}
              onClick={() => handleCardClick(card.text)}
            />
          ))}
        </Box>
        <Box
          className={`flex w-full items-center bg-gray-100 p-2 shadow-sm ${
            isMultiLine ? "rounded-3xl" : "rounded-full"
          }`}
        >
          <Tooltip title={"Change Mode"}>
            <button
              className={`mr-2 flex h-10 items-center justify-center rounded-full p-2 text-white ${
                isUrlMode
                  ? "bg-green-500 hover:bg-green-600"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
              onClick={() => {
                setIsUrlMode((prev) => !prev)
                setInputText("")
              }}
            >
              <span className="flex items-center">
                <Icons.Translator size={20} className="mr-2" />
                <span>{isUrlMode ? "URL Mode" : "Text Mode"}</span>
              </span>
            </button>
          </Tooltip>
          <TextareaAutosize
            value={inputText}
            onChange={handleInputChange}
            onHeightChange={handleHeightChange}
            placeholder={
              isUrlMode
                ? "Enter a website URL"
                : "Please create a video ad about..."
            }
            className={`flex-1 resize-none border-none bg-gray-100 px-4 py-2 focus:outline-none ${
              isMultiLine
                ? "overflow-auto rounded-3xl"
                : "overflow-hidden rounded-full"
            }`}
            minRows={1}
            maxRows={5}
          />
          <button
            onClick={handleSubmit}
            disabled={!inputText.trim() || isLoading}
            className={`ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-blue-500 p-2 text-white ${
              !inputText.trim() || isLoading
                ? "cursor-not-allowed bg-gray-300"
                : "hover:bg-blue-600"
            }`}
          >
            {isLoading ? <Icons.Loading size={20} /> : <Icons.Send size={20} />}
          </button>
        </Box>
        <Box className="mt-2 text-center text-sm text-gray-500">
          Need help?{" "}
          <button
            onClick={() => setShowGuideModal(true)}
            className="text-blue-500 underline hover:text-blue-700"
          >
            Check our guide here
          </button>
        </Box>
        {showPopup && (
          <Popup
            onClose={() => setShowPopup(false)}
            submitText={handleSubmit}
          />
        )}
        {showGuideModal && (
          <UserGuideModal
            showGuideModal
            closeModal={() => setShowGuideModal(false)}
          />
        )}
        {showProcessingModal && (
          <Modal
            title="Video Processing"
            open={showProcessingModal}
            closable={false}
            footer={[
              <Button key={0} onClick={() => setShowProcessingModal(false)}>
                Close
              </Button>,
            ]}
          >
            <p>
              Your video is currently being processed. The result will be sent
              to your email at {localStorage.getItem("email")} as soon as it’s
              ready.
            </p>
          </Modal>
        )}
        {errorMessage && (
          <Modal
            open={errorMessage !== ""}
            title={
              errorMessage === "COINS_REACH_LIMIT"
                ? "You've reached your free credits limit!"
                : ""
            }
            closable={false}
            footer={[
              <Button key={0} onClick={() => setErrorMessage("")}>
                Close
              </Button>,
            ]}
          >
            {errorMessage === "COINS_REACH_LIMIT" ? (
              <p>
                To continue exploring Letstok AI, you can view our paid plans{" "}
                <a
                  href="https://www.letstok.com/pricing"
                  className="text-blue-500"
                >
                  here
                </a>{" "}
                or{" "}
                <a
                  href="https://www.letstok.com/contact-us#book"
                  className="text-blue-500"
                >
                  contact us
                </a>{" "}
                to request more credits or book a demo to experience the full
                capabilities of our platform.
              </p>
            ) : (
              <p>{errorMessage}</p>
            )}
          </Modal>
        )}
      </Card>
      {!isUserLoggedIn && (
        <Box className="mt-20 text-center">
          <p className="text-xl font-bold">
            Ready to Unlock Letstok's Full Potential?
          </p>
          <p>
            Try our <b>limited-time</b> offer: Get the <b>Try Plan</b> for just{" "}
            <b>$39 USD!</b>{" "}
            <a href="https://www.letstok.com/pricing" className="text-blue-500">
              Click here to explore the offer.
            </a>
          </p>
          <p>
            Looking for a personalized walkthrough?{" "}
            <a
              href="https://www.letstok.com/contact-us#book"
              className="text-blue-500"
            >
              Book a demo
            </a>{" "}
            with our team to see how Letstok can transform your video marketing!
          </p>
        </Box>
      )}
    </Screen>
  )
}

export default TextGeneratorScreen
