import Env from "@utils/env"
import networkService from "services/network/network.service"
import { IUser } from "./users-api.interfaces"
import { LeadUserDTO } from "../assets/types"
async function addUnsignedUser({
  fullName,
  email,
  url,
  phone,
}: LeadUserDTO): Promise<Partial<IUser>> {
  const request_url = `${Env.get("REACT_APP_BASE_URL")}/ai/video/create-lead-user`

  const data = { fullName, email }
  if (url) {
    Object.assign(data, { url })
  }
  if (phone) {
    Object.assign(data, { phone })
  }
  const response = await networkService.axios
    .post(request_url, data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
  return response.data
}

export default addUnsignedUser
