import Env from "@utils/env"
import networkService from "services/network/network.service"

async function generateUsingUrl(url: string, userId: string, isLead: boolean) {
  const route = `${Env.get("REACT_APP_BASE_URL")}/ai/video/generate-from-url`

  return await networkService.axios
    .post(route, { url, userId, isLead })
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
}

export default generateUsingUrl
