import { useEffect, useState } from "react"
import commonService from "services/api/REST/common"
import appStore from "@stores/app/app.store"

export const useLoadCreatorsNames = () => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    (async () => {
      if (!appStore.creatorsNames.length) {
        setIsLoading(true)
        const creators = await commonService.getCreatorsNames().catch((e) => {
          console.warn(e.message)
          throw appStore.setNotify({
            title: "Request failed",
            desc: "Failed to get creators names",
          })
        })
        appStore.setCreatorsNames(creators)
        setIsLoading(false)
      }
    })()
  }, [])
  return { isLoading }
}
