import { Loader } from "@components/customized"
import { Suspense } from "react"
import { AdminMenuBar } from "./components"
import { Outlet } from "react-router-dom"
import useLoadCategories from "@utils/hooks/useLoadCategories"
import Notification from "@components/customized/notification/notification.component"
import { useLoadCreatorsNames } from "@utils/hooks"

const AdminLayout = () => {
  const { isLoading: isLoadingCategories } = useLoadCategories(true)
  const { isLoading: isLoadingCreators } = useLoadCreatorsNames()
  if (isLoadingCategories || isLoadingCreators) {
    return <Loader />
  }

  return (
    <>
      <Notification />
      <div className="flex h-screen flex-col overflow-hidden">
        <AdminMenuBar />
        <main className="h-full max-h-full overflow-auto bg-[var(--color-light-gray)]">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </>
  )
}
export default AdminLayout
