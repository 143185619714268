import { Layout } from "@components/customized"
import AdminLayout from "@screens/admin"
import { lazy } from "react"
import { Navigate, createBrowserRouter } from "react-router-dom"
import Protected from "./protected"
import { UserRoles } from "services/api/REST/users/users-api.interfaces"
import SwapUsersOrder from "@screens/admin/pages/swap-users-order"
import WatchAiVideoScreen from "@screens/text-generator/landing-page/landing-page.screen"
import TextGeneratorScreen from "@screens/text-generator/text-generator.screen"

const AuthScreen = lazy(() => import("@screens/auth/auth.screen"))
const AccountLayout = lazy(
  () => import("@screens/account/account-layout.screen")
)
const AccountDetailsScreen = lazy(
  () => import("@screens/account/pages/account-details/account-details.page")
)
const UserOrdersScreen = lazy(
  () => import("@screens/account/pages/user-orders/user-orders.screen")
)
const UserActivityScreen = lazy(
  () => import("@screens/account/pages/user-activity/user-activity.screen")
)
const HomeScreen = lazy(() => import("@screens/home/home.screen"))
const MyVideosScreen = lazy(() => import("@screens/my-videos/my-videos.screen"))
const NewOrderScreen = lazy(() => import("@screens/new-order/new-order.screen"))
const GenerateVideoScreen = lazy(
  () => import("@screens/generate-video/generate-video.screen")
)
const ScalerScreen = lazy(() => import("@screens/scaler/scaler.screen"))
const TranslatorScreen = lazy(
  () => import("@screens/translator/translator.screen")
)
const ErrorScreen = lazy(() => import("@screens/error/error.screen"))
const AssetsExplorerPage = lazy(
  () => import("@screens/admin/pages/assets-explorer")
)
const AssetsUploaderPage = lazy(
  () => import("@screens/admin/pages/assets-uploader")
)
const DraftOrdersPage = lazy(() => import("@screens/admin/pages/draft-orders"))

export const routes = {
  home: "/",
  login: "/login",
  myVideos: "/my-videos",
  newOrder: "/new-order",
  generateVideo: "/generate-video",
  textGenerator: "/text-generator",
  landingAiGenerator: "/landing-ai-generator",
  watchAiGenerator: "/watch-ai-generator",
  scaler: "/scaler",
  translator: "/translator",
  error: "/error",
  account: {
    root: "/account",
    orders: "/account/orders",
    activity: "/account/activity",
  },
  admin: {
    explorer: "/admin",
    draftOrders: "/admin/draft-orders",
    swapUsesOrder: "/admin/upload/swap-users-order",
    uploadAsset: {
      root: "/admin/upload",
      hook: "/admin/upload/hook",
      cta: "/admin/upload/cta",
      footage: "/admin/upload/footage",
      comment: "/admin/upload/comment",
      story: "/admin/upload/story",
      newOrder: "/admin/upload/new-order",
    },
  },
}

export const appRouter = createBrowserRouter([
  {
    path: "*",
    element: <AuthScreen />,
  },
  {
    path: routes.watchAiGenerator,
    element: <WatchAiVideoScreen />,
  },
  {
    path: routes.landingAiGenerator,
    element: <TextGeneratorScreen />,
  },
  {
    element: <Protected roles={[UserRoles.Regular]} Component={Layout} />,
    children: [
      { path: routes.home, element: <HomeScreen /> },
      {
        path: routes.account.root,
        element: <AccountLayout />,
        children: [
          {
            element: <AccountDetailsScreen />,
            index: true,
          },
          {
            element: <UserOrdersScreen />,
            path: routes.account.orders,
          },
          {
            element: <UserActivityScreen />,
            path: routes.account.activity,
          },
        ],
      },
      { path: routes.myVideos, element: <MyVideosScreen /> },
      { path: routes.newOrder, element: <NewOrderScreen /> },
      { path: routes.generateVideo, element: <GenerateVideoScreen /> },
      { path: routes.textGenerator, element: <TextGeneratorScreen /> },
      { path: routes.scaler, element: <ScalerScreen /> },
      { path: routes.translator, element: <TranslatorScreen /> },
      { path: routes.error, element: <ErrorScreen /> },
    ],
  },
  {
    element: (
      <Protected
        roles={[UserRoles.Admin, UserRoles.Editor]}
        Component={AdminLayout}
      />
    ),
    path: routes.admin.explorer,
    children: [
      { index: true, element: <AssetsExplorerPage /> },
      {
        path: `${routes.admin.uploadAsset.root}/:assetsType`,
        element: <AssetsUploaderPage />,
      },
      {
        path: `${routes.admin.draftOrders}`,
        element: <DraftOrdersPage />,
      },
      {
        path: `${routes.admin.swapUsesOrder}`,
        element: <SwapUsersOrder />,
      },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  },
])
