import { useEffect, useState } from "react"
import getAIVideoById from "services/api/REST/videos/get-ai-video.service"
import { AIVideo } from "services/api/REST/videos/videos-api.interfaces"

const useGetAIVideo = (videoId: number) => {
  const [video, setVideo] = useState<AIVideo | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        setLoading(true)
        const video = await getAIVideoById(videoId)
        setVideo(video)
      } catch (err) {
        setError("Failed to fetch video.")
      } finally {
        setLoading(false)
      }
    }

    fetchVideo()
  }, [videoId])

  return { video, loading, error }
}

export default useGetAIVideo
