import { routes } from "@router/router"
import { useLocation, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { cn } from "@utils/styles/cn"
import { Text } from "@components/controllers"
import { Box, Icons } from "@components/customized"

const Sidebar: React.FC<{ onLinkClick?: () => void }> = ({ onLinkClick }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const navLinks = useMemo(
    () => [
      { title: "Home", href: routes.home, icon: Icons.Home },
      {
        title: "Generator",
        href: routes.generateVideo,
        icon: Icons.Generator,
      },
      {
        title: "Text Generator",
        href: routes.textGenerator,
        icon: Icons.Generator,
      },
      {
        title: "New order",
        href: routes.newOrder,
        icon: Icons.NewOrder,
      },

      { title: "Scaler", href: routes.scaler, icon: Icons.Scaler },
      { title: "Translator", href: routes.translator, icon: Icons.Translator },
      { title: "My Videos", href: routes.myVideos, icon: Icons.MyVideos },
    ],
    []
  )
  return (
    <aside className="flex h-full flex-col border-[var(--color-gray)] lg:border-r">
      <div className="sticky top-[0px] flex flex-col gap-y-2 p-3">
        {navLinks.map((link) => {
          const isActive = location.pathname === link.href
          return (
            <Box
              onClick={() => {
                onLinkClick && onLinkClick()
                navigate(link.href)
              }}
              key={link.href}
              className={cn(
                `flex cursor-pointer items-center gap-x-4  rounded-[var(--b-radius)]
            px-5 py-3 transition duration-200`,
                isActive
                  ? "primary-gradient"
                  : "hover:bg-[var(--color-light-gray)]"
              )}
            >
              <link.icon
                className={cn("text-[20px]", isActive && "text-white")}
              />
              <Text type="text2" className={cn(isActive && "text-white")}>
                {link.title}
              </Text>
            </Box>
          )
        })}
      </div>
    </aside>
  )
}
export default Sidebar
